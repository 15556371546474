import React from 'react'
import { graphql } from 'gatsby'
import { shuffle } from 'lodash-es'
import { motion } from 'framer-motion'
import cn from 'classnames'
import Img from 'gatsby-image'

import { S3ImageAssetConnection } from '../types/server-types'
import { marginClass } from '../library/spacing'
import { Layout } from '../templates'
import { PageProps } from './index'
import {
  Container,
  Columns,
  Column,
  AnchorButton,
  Heading,
  FadeIn,
  ResizeSensor,
} from '../components'

interface AboutPageProps {
  aboutLg: S3ImageAssetConnection
  aboutSm: S3ImageAssetConnection
}

const AboutPage: React.FunctionComponent<PageProps<AboutPageProps>> = (props) => {
  const { data } = props
  return (
    <Layout>
      <div className="spacer" style={{ height: '40vh', minHeight: 80 }}></div>
      <Container maxWidth="lg">
        <Columns className={marginClass([null, null, 12])}>
          <Column>
            <FadeIn tagName="div">
              <Revealer options={[
                'Breakfast enthusiast.',
                'Hobby collector.',
                'Podcast listener.',
                'Pie baker.',
                'Continual learner.',
                'Alliteration advocate.',
                'Houseplant hoarder.',
                'Television addict.',
                'Tea drinker.',
                'Pretty nice guy.',
              ]} />
            </FadeIn>
          </Column>
        </Columns>
      </Container>

      <Container maxWidth="xl">
        <Columns align="middle">
          <Column
            span={{ xs: 12, md: 6, lg: 4 }}
            className={cn(
              marginClass([null, null, { xs: 6, md: 0 }]),
            )}>
            {data.aboutLg.nodes[0] &&
              <Img
                fluid={[
                  { ...data.aboutSm.nodes[0].childImageSharp.fluid }
                ]}
                style={{ borderRadius: 48 }}
              />
            }
          </Column>
          <Column offset={{ sm: 1 }}>
            <Columns>
              <Column span={{ xs: 12, lg: 6 }} className={marginClass([null, null, { xs: 8, md: 6, lg: 0 }])}>
                <Heading size={2} margin={[null, null, 2]} className="">Currently</Heading>
                <Heading size={2} family="mono" className={'c--gray-light'}>UX Developer at Adair Homes</Heading>

                <Heading size={2} margin={[6, null, 2]} className="">Location</Heading>
                <Heading size={2} family="mono" className={'c--gray-light'}>Portland, Oregon</Heading>

                <Heading size={2} margin={[6, null, 2]}>Contact</Heading>
                <Heading size={2} family="mono" className={'c--gray-light'}>adam.volkman@gmail.com</Heading>
              </Column>
              <Column span={{ xs: 12, lg: 6 }}>
                <Heading size={2} margin={[null, null, 2]}>Other Projects</Heading>
                <ul className="t--mono">
                  <li><a target="_blank" href="http://face-its.adamvolkman.com">Face-its</a></li>
                </ul>

                <Heading size={2} margin={[6, null, 2]} className="">Elsewhere</Heading>
                <ul className="t--mono">
                  <li><a href="https://www.linkedin.com/in/adamvolkman/" target="_blank">LinkedIn</a></li>
                  <li><a href="https://github.com/bleepblorb" target="_blank">Github</a></li>
                  <li><a href="http://adamvolkman.tumblr.com" target="_blank">Tumblr</a></li>
                  <li><a href="https://twitter.com/adam_volkman" target="_blank">Twitter</a></li>
                </ul>
              </Column>
            </Columns>
            <Columns className={marginClass([8, null, null])}>
              <Column>
                <p>
                  <AnchorButton href="/resume" intent="primary">View Resume</AnchorButton>
                </p>
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Container>

      <Container maxWidth="md" className={marginClass([10, null, 12])}>
        <Columns>
          <Column>
            <p className="t--large">
              For information on me that is actually useful you'll want to take
              a look at my resume. If you're interested in the tech/design behind
              the website you can take a look <a href="https://bitbucket.org/adam_volkman/well-done">here.</a>
            </p>
            <p>Thanks for stopping by.</p>
          </Column>
        </Columns>
      </Container>

    </Layout >
  )
}

export default AboutPage

export const query = graphql`
  query AboutPageQuery{
    aboutSm: allS3ImageAsset(filter: {Key: {eq: "about/about-portrait-tall.jpg"}}) {
      nodes {
        childImageSharp {
          fluid(maxWidth:560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    aboutLg: allS3ImageAsset(filter: {Key: {eq: "about/about-portrait-wide.jpg"}}) {
      nodes {
        childImageSharp {
          fluid(maxWidth:1024) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }

  }
`

interface RevealerOptions {
  options: string[]
}

export const Revealer: React.FunctionComponent<RevealerOptions> = (props) => {
  const [randomOptions, setRandomOptions] = React.useState<string[]>(props.options)
  const [containerHeight, setContainerHeight] = React.useState<number>(0)
  const [masterHeight, setMasterHeight] = React.useState<number>(0)
  const [prevMasterHeight, setPrevMasterHeight] = React.useState<number>(0)
  const [showCount, setShowCount] = React.useState<number>(1)
  const containerRef = React.useRef<HTMLDivElement>()
  const body = React.useRef<HTMLBodyElement>(null)

  React.useEffect(() => {
    body.current = document.querySelector('body')
  }, [])

  React.useEffect(() => {
    const randomOptions = shuffle(props.options)
    randomOptions.push("Thats about it...")

    setRandomOptions(randomOptions)
  }, props.options)

  React.useLayoutEffect(() => {
    console.log('masterHeight change', masterHeight)
    if (body.current && showCount > 1) {
      body.current.style.overflow = 'hidden'
      window.scrollBy(0, containerHeight - prevMasterHeight)
      window.setTimeout(() => {
        body.current.style.overflow = ''
      })
    }
    setPrevMasterHeight(masterHeight)
  }, [masterHeight])

  React.useEffect(() => {
    const height = getContainerHeight()
    setContainerHeight(height)
  }, [showCount])

  function handleLoadMore(e: React.MouseEvent) {
    e.preventDefault()
    if (randomOptions.length > showCount) {
      setShowCount(showCount + 1)
    }
  }

  function getContainerHeight() {
    let height = 0
    if (!containerRef.current) return height

    for (let i = 0; i < containerRef.current.children.length; i++) {
      height += containerRef.current.children[i].getBoundingClientRect().height
    }
    return height
  }

  function handleResize() {
    const height = getContainerHeight()
    setContainerHeight(height)
  }

  function handleComplete() {
    setMasterHeight(containerHeight)
  }

  function renderOptions(options: string[] = []) {
    return randomOptions.map((option, index) => {
      return (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 16 }}
          animate={{ opacity: 1, y: 0, transition: { duration: .45, delay: .15 } }}
          className="revealer__item"
        >
          {option}
        </motion.div>
      )
    }).slice(0, showCount)
  }

  return (
    <ResizeSensor onResize={handleResize}>
      <Column span={12} className="about__intro">
        <div className="revealer" style={{ height: masterHeight }}>
          <motion.div
            className="revealer__items"
            ref={containerRef}
            initial={false}
            animate={{ height: containerHeight, transition: { duration: showCount > 1 ? .35 : 0 } }}
            onAnimationComplete={handleComplete}
          >
            <Heading size={2} family="mono" className="c--gray-light">Adam Volkman —</Heading>
            <Heading size={6}>Designer, developer, doodler.</Heading>
            {renderOptions()}
          </motion.div>
        </div>
        {showCount < randomOptions.length &&
          <div className="revealer__clicker" onClick={handleLoadMore}>+ more</div>
        }
      </Column>
    </ResizeSensor>
  )
}
